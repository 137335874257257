import {
  Accordion,
  AccordionButton,
  Text,
  AccordionIcon,
  AccordionPanel,
  Box,
  AccordionItem,
  Center,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";
// import AccordionItem from "react-bootstrap/esm/AccordionItem";

const SportEquiInf = () => {
  return (
    <div
    // style={{ backgroundImage: 'linear-gradient(to bottom, #0a3272, white)' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sports Events Organized | Sports Championships | BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की आयोजन और संगठन पेज: इस पेज पर विभिन्न खेल आयोजनों और संबंधित गतिविधियों के आयोजन की जानकारी उपलब्ध है। यह पेज खेल से जुड़े कार्यक्रमों के बारे में विस्तृत जानकारी प्रदान करता है।"
        />
      </Helmet>
      {/* <Breadcrumb
                pageName="Sports-Equipment-Information"
                pageTitle="Sports-Equipment-Information"
                id="#Sports-Equipment-Information"
                img_url="./img/Sports-Equipment-Information-desktop.jpg"
                mobile_banner="./img/Sports-Equipment-Information-mobile.jpg"
            /> */}

      <Center p={6} m={6}>
        <Accordion defaultIndex={[0]}>
          <Box boxShadow="lg" p="1" rounded="md" bg="#c0c9d0">
            <Box
              style={
                {
                  // border: "2px solid red",
                }
              }
              // width="40%"
              m="auto"
              // borderTopLeftRadius="15px"
              // borderTopRightRadius="15px"
              // borderBottomLeftRadius="50%"
              // borderBottomRightRadius="50%"

              height="0px"
            ></Box>
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize={["18", "24"]}
              letterSpacing="wide"
              color="#fcffff"
              px={[4, 10, 20]}
              backgroundColor="#0b2651"
              width="40%"
              m="auto"
              borderTopLeftRadius="15px"
              borderTopRightRadius="15px"
              borderBottomLeftRadius="15px"
              borderBottomRightRadius="15px"
            >
              आयोजित कार्यक्रम
            </Text>
          </Box>

          {/* new event added from here */}
          <Box
            bg="#0b244d"
            marginLeft={3}
            marginRight={3}
            borderBottomLeftRadius={25}
            borderBottomRightRadius={25}
          >
            {/* new data  */}

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    मेडल लाओ नौकरी पाओ योजना के तहत राज्य के उत्कृष्ट खिलाड़ियों
                    को नौकरी का उपहार
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दिनांक 06 जनवरी 2023 को नेक संवाद, मुख्यमंत्री आवास, 1 अणे
                मार्ग, पटना में सुबह 11 बजे से बिहार के माननीय मुख्यमंत्री श्री
                नीतीश कुमार जी ने अपने कर कमलों द्वारा राज्य के उत्कृष्ट
                खिलाड़ियों को नियुक्ति पत्र प्रदान किया। खेल जगत में बिहार के
                खिलाड़ियों के अद्भुत और शानदार प्रदर्शन से राज्य को एक नई पहचान
                मिली है।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    67 वां नेशनल स्कूल गेम्स , सेपक टाकरा चैंपियनशिप
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                पाटलिपुत्र खेल परिसर, कंकड़बाग,पटना में दिनांक 03 जनवरी 2024 से
                07 जनवरी 2024 तक 67 वें नेशनल स्कूल गेम्स , सेपक टाकरा
                चैंपियनशिप का सफल आयोजन किया गया। सेपक टाकरा चैंपियनशिप 2024 के
                फाइनल का परिणाम
                <br />
                <span style={{ color: "red" }}>अंडर-19 बालक </span> <br />
                <span>प्रथम: बिहार </span> <br />
                <span>दूसरा स्थान: दिल्ली </span> <br />
                <span> तीसरा स्थान: मणिपुर </span> <br />
                <span>चौथा स्थान: तेलंगाना </span> <br />
                <span style={{ color: "red" }}>अंडर-19 बालिका </span> <br />
                <span>प्रथम: मणिपुर </span> <br />
                <span>दूसरा स्थान: बिहार </span> <br />
                <span> तीसरा स्थान: ओडिशा </span> <br />
                <span>चौथा स्थान: केरला </span> <br />
                <span style={{ color: "red" }}>अंडर-17 बालक</span> <br />
                <span>प्रथम: मणिपुर </span> <br />
                <span>दूसरा स्थान: दिल्ली </span> <br />
                <span>तीसरा स्थान: बिहार </span> <br />
                <span>चौथा स्थान: नागालैंड </span> <br />
                <span style={{ color: "red" }}>अंडर-17 बालक</span> <br />
                <span> प्रथम: मणिपुर</span> <br />
                <span>दूसरा स्थान: राजस्थान </span> <br />
                <span> तीसरा स्थान: बिहार </span> <br />
                <span>चौथा स्थान: आंध्रप्रदेश </span> <br />
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    58 वां नेशनल क्रॉस कंट्री चैंपियनशिप 2024
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दिनांक 15 जनवरी 2024 को बिहार के गया जिले के मगध यूनिवर्सिटी
                मैदान में 58 वें नेशनल क्रॉस कंट्री चैंपियनशिप का सफल आयोजन किया
                गया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    12 वां नेशनल स्कूल चेस चैंपियनशिप 2024
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                12 वां नेशनल स्कूल चेस चैंपियनशिप 2024 का आयोजन बिहार राज्य खेल
                प्राधिकरण एवं ऑल बिहार चेस एसोसिएशन के संयुक्त तत्वावधान में
                दिनांक 6-10 फरवरी 2024 तक पटना के ज्ञान भवन में किया गया। इस चेस
                चैंपियनशिप में अंडर -7, 9, 11, 13, 15, और 17 आयु वर्ग के बालक
                एवं बालिका दोनों वर्गों के खिलाड़ियों ने भाग लिया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    67 वां नेशनल स्कूल गेम्स भारोत्तोलन प्रतियोगिता 2023-24
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                बिहार के पाटलिपुत्र खेल परिसर कंकड़बाग,पटना में दिनांक 13-16
                फरवरी 2024 तक 67 वें नेशनल स्कूल गेम्स भारोत्तोलन प्रतियोगिता
                2023-24 का सफल आयोजन किया गया। इस प्रतियोगिता में अंडर-17 आयु
                वर्ग के देशभर के बालक एवं बालिका दोनों वर्ग के खिलाड़ियों का
                बेहतर खेल कौशल नजर आया।{" "}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    कबड्डी के लिए महिला खिलाड़ियों का चयन
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दिनांक 23 और 24 फरवरी 2024 को पाटलिपुत्र खेल परिसर, कंकड़बाग,
                पटना में महिला कबड्डी खिलाड़ियों का चयन किया गया। ध्यानचंद
                पुरस्कार से सम्मानित,श्रेष्ठ कबड्डी कोच कविता ठाकुर जी के
                मार्गदर्शन में यह चयन परीक्षण आयोजित किया गया।{" "}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    67 वें नेशनल स्कूल क्रिकेट चैम्पियनशिप 2023-24
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दिनांक 16 जनवरी 2024 से 23 जनवरी 2024 तक पटना के ऊर्जा स्टेडियम
                में 67 वें नेशनल स्कूल क्रिकेट चैम्पियनशिप का सफल आयोजन किया गया
                ।
              </AccordionPanel>
            </AccordionItem>

            {/*  new data */}
            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    वॉलीबॉल प्रीमियर लीग
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                बिहार में पहली बार बिहार वॉलीबॉल प्रीमियर लीग का आयोजन बिहार
                राज्य खेल प्राधिकरण एवं बिहार वॉलीबॉल एसोसिएशन के संयुक्त
                तत्वावधान में दिनांक 30 सितम्बर को पाटलिपुत्र खेल परिसर,
                कंकड़बाग, पटना मेंआयोजित किया गया। इस लीग में कुल 08 टीमों ने
                भाग लिया। प्रत्येक टीम में 06 सिनियर वर्ग के एवं 04 जूनियर एवं
                यूथ वर्ग के खिलाड़ी शामिल रहे। इसके अतिरिक्त प्रत्येक टीम में 02
                खिलाड़ी अन्तर्राष्ट्रीय स्तर के भी थे। इस लीग का आयोजन राज्य के
                04 जिलों क्रमशः भागलपुर, बेगुसराय, सारण एवं पटना में किया गया।
                इस लीग में विजेता टीम को 7.5 लाख एवं उपविजेता टीम को 5 लाख रूपये
                तथा तृतीय स्थान पर रहने वाली टीम को 3 लाख रूपये की पुरस्कार राशि
                प्रदान की गई।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    द्वितीय बैडमिंटन प्रतिभा खोज
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                बिहार राज्य खेल प्राधिकरण द्वारा दिनांक 28 सितंबर 2023 से 30
                सितंबर 2023 तक भागलपुर इंडोर स्टेडियम में अंडर 15,अंडर 17 और
                अंडर 19 वर्ग के बालक एवं बालिका समूह के लिए द्वितीय बैडमिंटन
                प्रतिभा खोज कार्यक्रम का सफलतापूर्वक आयोजन किया गया। इस प्रतिभा
                खोज कार्यक्रम के दौरान 35 बैडमिंटन के खिलाड़ियों का चयन किया
                गया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    कुश्ती चयन शिविर
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                स्कूल गेम्स में भागीदारी के लिए दिनांक 26 एवं 27 सितंबर 2023 को
                छपरा में कुश्ती अंडर 14 और अंडर-17 आयु वर्ग के बालक एवम्
                बालिकाओं के लिए चयन शिविर का सफल आयोजन किया गया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    सिंपली स्माईल्स कार्यशाला
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                बिहार राज्य खेल प्राधिकरण की ओर से राज्य भर के सभी प्रशिक्षक एवं
                जिला खेल पदाधिकारियों के लिए दिनांक 18 सितंबर 2023 को,
                पाटलिपुत्र खेल परिसर में सिंपली स्माईल्स कार्यशाला का सफल आयोजन
                किया गया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    सिंपली पीरियड्स कार्यशाला
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                राज्य में महिला खिलाड़ियों और प्रशिक्षकों में स्वास्थ्य के प्रति
                जागरूकता बढ़ाने के लक्ष्य को पूरा करने हेतु बिहार राज्य खेल
                प्राधिकरण की ओर से दिनांक 13 सितंबर से 18 सितंबर के बीच सिंपली
                पीरियड्स की कार्यशाला का सफलतापूर्वक संचालन किया गया।
              </AccordionPanel>
            </AccordionItem>

            {/* old event */}

            <AccordionItem>
              <h1>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    खेल सम्मान समारोह 2023
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h1>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                पटना के ज्ञान भवन में आयोजित ‘बिहार खेल सम्मान समारोह का विधिवत
                उद्घाटन मुख्य अतिथि कला, संस्कृति एवं युवा विभाग के मंत्री श्री
                जितेंद्र कुमार राय द्वारा 29 अगस्त 2023 को किया गया। इस आयोजन की
                विशिष्ट अतिथि कला, संस्कृति एवं युवा विभाग की अपर मुख्य सचिव
                श्रीमती हरजोत कौर बम्हरा रहीं। इस वर्ष बिहार राज्य खेल सम्मान
                समारोह में 42 खेल विधाओं के समान्य और दिव्यांग श्रेणी में 400
                खिलाड़ियों और 11 प्रशिक्षकों सहित कुल 411 लोगों को 5 करोड़ से
                ज्यादा की सम्मान राशि दी गई जो पिछले वर्ष से काफी ज्यादा है। इस
                वर्ष अंतर्राष्ट्रीय सम्मान पाने वाले खिलाड़ियों में 6 महिला और 11
                पुरुष सहित कुल 17 खिलाड़ी हैं और राष्ट्रीय सम्मान पाने वालों में
                181 पुरुष और 162 महिला खिलाड़ियों सहित कुल 283 खिलाड़ी हैं।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    प्रशिक्षण शिविर
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                बिहार राज्य खेल प्राधिकरण एवं श्री रामचंद्र खेल विज्ञान संस्थान
                के संयुक्त तत्वावधान में बिहार के प्रशिक्षकों के लिए दिनांक 30
                मई 2023 को राज्य भर के प्रशिक्षकों के लिए प्रशिक्षण शिविर का
                भव्य आयोजन दशरथ मांझी संस्थान सभागार, पटना में किया गया।इस
                प्रशिक्षण शिविर में दक्षिण अफ्रीका के ट्रेनर एंड्र्यू ग्रे और
                श्री वी. भास्करन, ओलंपियन, भारतीय हॉकी टीम के पूर्व कप्तान एवं
                कोच शामिल रहें।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    स्पोर्ट्स कॉन्क्लेव 2.0
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                स्पोर्ट्स कॉन्क्लेव 2.0 का सफल आयोजन 19 और 20 मई 2023 को पटना के
                ज्ञान भवन में हुआ । कार्यक्रम का विषय था "बिहार - एक उत्कृष्ट
                खेल केंद्र बन रहा है"। स्पोर्ट्स कॉन्क्लेव का उद्देश्य खेल के
                उत्तम बुनियादी ढांचा,एथलीटों की देखभाल एवं उनका प्रबंधन, जमीनी
                स्तर के खेल विकास और ईस्पोर्ट्स जैसे सर्वोत्तम खेल विषयों पर
                समाज और राज्य का ध्यान केंद्रित करना था। सरकार द्वारा आयोजित इस
                कार्यक्रम में अधिकारियों, राष्ट्रीय और राज्य खेल महासंघों, नीति
                निर्माताओं, व्यावसायिक उद्यमों और खेल उद्योग के कई प्रमुख
                हितधारकों ने भाग लिया।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    नीडजैम 2023
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                कम उम्र में ही देश भर से भविष्य के विजेताओं को तलाशने और तराशने
                के उद्देश्य के साथ वर्ष 2003 में एथलेटिक्स फेडरेशन ऑफ इंडिया AFI
                द्वारा NIDJAM यानि नेशनल इंटर डिस्ट्रिक्ट जूनियर ऐथेलेटिक्स मीट
                की शुरुआत हुई। निडजैम को विश्व की सबसे बड़ी बुनियादी प्रतिभा खोज
                प्रतियोगिता माना गया है। इसमें जिला स्तर पर प्रतियोगिता में एक
                लाख से ज्यादा खिलाड़ी हिस्सा लेते हैं जो अपने जिले से चुने जाने
                के बाद अंतर जिला प्रतियोगिता के लिए आते हैं। 9 से 12 फरवरी तक
                पाटलिपुत्र खेल परिसर , कंकड़बाग , पटना में आयोजित 18 वें निडजैम
                2023 में देश के 600 जिलों से 6000 से ज्यादा 14 और 16 वर्ष से कम
                आयुवर्ग के लड़के लड़कियों के साथ 1500 से ज्यादा उनके प्रशिक्षक
                और मैनेजर इस निडजैम में शामिल हुए । जिसमें से बिहार के 38 जिलों
                से 513 खिलाड़ी भी इसमें शामिल रहें । वर्ष 2023 में बिहार में
                आयोजित निडजैम अब तक का सबसे बड़ा आयोजन रहा।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    राजा कर्ण तीरंदाजी (लक्ष्य)
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                राज्य भर में तीरंदाज़ी के युवा खिलाड़ियों के प्रतिभा को एक बेहतर
                मंच प्रदान करने के उद्देश्य से राजा कर्ण ओपन बिहार स्टेट
                तीरंदाजी प्रतियोगिता का आयोजन प्रतिवर्ष किया जाता है। इस
                प्रतियोगिता में राज्य भर से खिलाड़ी सम्मिलित होते हैं। जिसमें से
                प्रथम, द्वितीय और तृतीय स्थान प्राप्त करने वाले खिलाड़ियों को
                सम्मानित करने के साथ उन्हें बिहार राज्य खेल प्राधिकरण की ओर से
                सहायता राशि भी प्रदान की जाती है।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    खेल सम्मान समारोह 2018
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                29 अगस्त को स्वर्गीय ध्यानचंद जी के सम्मान में उनकी जयंती पर
                मनाए जाने वाले राष्ट्रीय खेल दिवस के अवसर पर राष्ट्रीय तथा
                अंतर्राष्ट्रीय प्रतियोगिताओं में पदक जीत कर बिहार को गौरवान्वित
                करने वाले बिहार के उत्कृष्ट खिलाड़ियों और प्रशिक्षकों को प्रशस्ति
                पत्र एवं सम्मान राशि देकर सम्मानित करने के लिए कला, संस्कृति एवं
                युवा विभाग तथा बिहार राज्य खेल प्राधिकरण द्वारा हर वर्ष ‘बिहार
                राज्य खेल सम्मान’ समारोह का आयोजन किया जाता है। बिहार राज्य खेल
                सम्मान समारोह 2018 का आयोजन पटना के ऊर्जा भवन में किया गया था।
                जिसमें 39 खेल विधाओं के 317 खिलाड़ियों और 6 प्रशिक्षकों को
                सम्मानित किया गया। इस सम्मान समारोह में खिलाड़ियों और
                प्रशिक्षकों के बीच 3 करोड़ 11 लाख की राशि वितरित की गई।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    स्पोर्ट्स कॉन्क्लेव
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दो दिवसीय बिहार स्पोर्ट्स कॉन्क्लेव 2018 गुरुवार, 29 अप्रैल को
                राज्य की राजधानी पटना के बिहार संग्रहालय में भव्य उद्घाटन के साथ
                शुरू हुआ। कार्यक्रम का प्राथमिक उद्देश्य बिहार में खेलों में
                समग्र रूप से सुधार करना रहा।बिहार राज्य खेल प्राधिकरण द्वारा लॉस
                एंजिल्स में 2028 ग्रीष्मकालीन ओलंपिक में बिहार की खेल प्रतिभा की
                बेहतर भागीदारी और सफलता सुनिश्चित करने के मिशन के साथ बिहार
                स्पोर्ट्स कॉन्क्लेव 2018 का आयोजन किया गया था।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    दक्ष
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                दक्ष एक वार्षिक प्रतियोगिता है जिसका आयोजन प्रखंड , जिला ,
                कमिश्नरी एवम् राज्य स्तर पर किया जाता है। इस प्रतियोगिता का
                आयोजन अंडर-14, अंडर-17 और अंडर-19 आयु वर्ग के बालक और बालिका के
                लिए किया जाता है।
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem
              borderBottomLeftRadius={25}
              borderBottomRightRadius={25}
            >
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize={["16", "18"]}
                    color="#f6ffff"
                    p="3"
                  >
                    तरंग
                  </Box>
                  <AccordionIcon color="#f6ffff" fontSize={25} />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                textAlign="left"
                fontSize={["13", "18"]}
                color="#f6ffff"
              >
                तरंग एक स्कूल स्तरीय प्रतियोगिता है जिसका संचालन बिहार राज्य खेल
                प्राधिकरण एवम् शिक्षा विभाग के संयुक्त तत्वधान में किया जाता है।
                इस प्रतियोगिता में राज्य भर के सभी सरकारी एवम् प्राइवेट स्कूल के
                विद्यार्थी भाग लेते हैं। यह प्रतियोगिता अंडर-12, अंडर-14,
                अंडर-17 और अंडर-19 आयु वर्ग के विद्यार्थियों के लिए प्रत्येक
                वर्ष आयोजित की जाती है।
              </AccordionPanel>
            </AccordionItem>
          </Box>
        </Accordion>
      </Center>
    </div>
  );
};

export default SportEquiInf;
