import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import TopHeadlineCard from "./TopHeadlineCard";
import Styled from "../pages/ESportLinks/ESportLinks.module.css";

const cards = [
  {
    id: 6,
    title: "पेरिस ओलंपिक खेल 2024 में भारत ",
    text: "पेरिस ओलंपिक खेल 2024 का आयोजन 26 जुलाई 2024 से 11 अगस्त 2024 तक किया जा रहा है। पेरिस ओलंपिक 2024 में  भारत की ओर से 16 खेल विधाओं में 117 एथलीट हिस्सा लेंगे, जिनमें 70 पुरुष और 47 महिलाएँ शामिल हैं। वे 95 पदकों के लिए 69 स्पर्धाओं में भाग लेंगे। पेरिस ओलिंपिक 2024 में भारत द्वारा पदक प्राप्ति की वर्तमान स्थिति एवं खेल कार्यक्रम और परिणाम की विस्तृत जानकारी के लिए क्लिक करें।",
    image: "img/parisOlampic.jpeg",
    url: "/olampic-Schedule-Table",
  },
  {
    id: 1,
    title: "प्रथम बिहार वुमेन कबड्डी लीग 2024",
    text: "बिहार राज्य खेल प्राधिकरण द्वारा प्रथम बिहार वूमेन कबड्डी लीग का आयोजन 10 जून से 16 जून, 2024 तक पाटलिपुत्र खेल परिसर, इंडोर स्टेडियम, पटना में होने जा रहा है जिसमें 06 टीमें ( सीतामढ़ी सेंटीनेल्स  ,मगध वरियर्स ,सारण स्ट्राइकर्स , पटना पेलिकन , नालंदा निंजास ,सीवान टाइटेंस )  हिस्सा ले रहीं हैं।",
    image: "img/kabbadilig.jpg",
    url: "/bihar-Women-Kabaddi-League",
  },
  {
    id: 2,
    title: "67 वां नेशनल स्कूल गेम्स 2023",
    text: "स्कूल गेम्स फेडरेशन ऑफ इंडिया के द्वारा 67वां नेशनल स्कूल गेम्स 2023 में 05 खेल विधाओं  एथलेटिक्स, क्रिकेट, सेपक टाकरा, भारोत्तोलन और फुटबॉल  का आयोजन बिहार में किया जा रहा है।",
    image: "img/sixty_seventh_sgfi.jpg",
    url: "/national-school-games",
  },
  {
    id: 4,
    title: " प्रो कबड्डी लीग 10वां सीजन 2023-24",
    text: "प्रो कबड्डी लीग के 10वें सीजन का आयोजन 2 दिसंबर 2023 से 21 फरवरी 2024 तक किया जा रहा है। इस वर्ष पटना पाइरेट्स टीम को बिहार सरकार एवं बिहार राज्य खेल प्राधिकरण प्रायोजित कर रही है।",
    image: "img/ProKabaddi.jpg",
    url: "/prokabaddi",
  },
  {
    id: 3,
    title: "चेस चैंपियनशिप 2024",
    text: " बिहार राज्य खेल प्राधिकरण के द्वारा चेस चैंपियनशिप का आयोजन दिनांक: 05 फरवरी 2024 से 10 फरवरी 2024 तक  किया जा रहा है।  ",
    image: "img/Chess.jpg",
    url: "#",
  },
  {
    id: 5,
    title: "ई स्पोर्ट्स प्रतियोगिता 2023",
    text: "बिहार राज्य खेल प्राधिकरण के द्वारा बिहार में पहली बार ई- स्पोर्ट्स प्रतियोगिता का आयोजन दिनांक 16 दिसंबर से 22 दिसंबर  2023 तक किया जा रहा है । इस प्रतियोगिता का ग्रैंड फिनाले 28 दिसंबर 2023 को पाटलिपुत्र खेल परिसर में ही खेला जाएगा। ई-स्पोर्ट्स प्रतियोगिता के अंतर्गत खिलाड़ी विभिन्न 4 ऑनलाइन खेलों के लिए अपना पंजीकरण कर सकते हैं।",
    image: "img/ESport.jpg",
    url: "/esportlinks",
  },

  // {
  //   id: 2,
  //   title: "नेशनल गेम्स - 2023",
  //   text: "नेशनल गेम्स का आयोजन दिनांक 25 अक्टूबर से 09 नवंबर 2023 तक किया जा रहा है। भारत का नाम विश्व पटल पर गौरवान्वित करने वाले कई दिग्गज खिलाड़ी इस प्रतियोगिता में सहभागिता कर चुके हैं।प्रथम नेशनल गेम्स का आयोजन अविभाजित भारत के लाहौर शहर में वर्ष 1924 में किया गया था। साल 2022 में जहां नेशनल गेम्स में 36 विभिन्न खेल विषयों को शामिल किया गया था वहीं इस बार खेल विधाओं संख्या बढ़कर 43 हो गई है।",
  //   image: "img/national-games.jpeg",
  //   url: "/nationalgames",
  // },
];

const NewTopHeadline = () => {
  return (
    <div
      className={`container`}
      style={{
        borderRadius: "8px",
        marginTop: "15px",
        marginBottom: "5px",
      }}
    >
      <Tabs isManual variant="enclosed">
        <TabList>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            पेरिस ओलंपिक खेल 2024 में भारत
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            प्रथम बिहार वुमेन कबड्डी लीग 2024
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            67 वां नेशनल स्कूल गेम्स 2023
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            प्रो कबड्डी लीग 10वां सीजन
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            चेस चैंपियनशिप- 2024
          </Tab>
          <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            ई स्पोर्ट्स प्रतियोगिता 2023
          </Tab>
          {/* <Tab className={`${Styled.text_tab}`} fontSize="2xl" as="b">
            नेशनल गेम्स - 2023
          </Tab> */}
        </TabList>
        <TabPanels
          style={{
            borderLeft: "1px solid #cfd9de",
            borderRight: "1px solid #cfd9de",
            borderBottom: "1px solid #cfd9de",
            borderRadius: "0px 0px 5px 5px",
          }}
        >
          {/* initially mounted */}

          {cards.map((card, index) => (
            <TabPanel key={index}>
              <TopHeadlineCard card={card} />
            </TabPanel>
          ))}

          {/* <TabPanel>
            <p>one!</p>
          </TabPanel>
          initially not mounted
          <TabPanel>
            <p>two!</p>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default NewTopHeadline;
