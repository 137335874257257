export const TenderData = [
  {
    "key": "T8",
    "eventName": "tenders",
    "date": "10/07/2024,",
    "expiryDate": "----",
    "name": " EMPANELMENT OF TRANSPORATION AGENCY(PR No -001689(B & C) 2024 - 25 से संबंधित निविदा की प्रस्तुतिकरण से संबंधित सूचना",
    "corrigendum": "",
    "url": "img/tenders/L.No. 2457 dt.10.07.2024.PDF"
  },
  {
    "key": "T8",
    "eventName": "tenders",
    "date": "05/07/2024,",
    "expiryDate": "10/07/2024",
    "name": "पाटलिपुत्र खेल परिसर कंकड़बाग पटना में मेस (कैन्टीन) संचालन हेतु प्रकाशित निविदा से संबंधित शुद्धि पत्र (Corrigendum) - 02",
    "corrigendum": "",
    "url": "img/tenders/CORRIGNEDUM_2_RELATED_TO_MESS.PDF"
  },
  {
    "key": "T7",
    "eventName": "tenders",
    "date": "02/07/2024,",
    "expiryDate": "",
    "name": "दिनांक 24/06/2024 को स्पोर्ट्स किट से संबंधित प्री-बीड बैठक में प्राप्त सुझाव एवं अन्य बिंदुओं से संबंधित शुद्वि पत्र",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum Related to Sports Kit RFP.PDF"
  },
  {
    "key": "T6",
    "eventName": "tenders",
    "date": "02/07/2024,",
    "expiryDate": "",
    "name": "पाटलिपुत्र खेल परिसर छात्रावास के लिए कैटरिंग एजेंसी के चयन संबंधित निविदा के लिए शुद्धि पत्र",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum 2 related to MESS.PDF"
  },
  {
    "key": "T5",
    "eventName": "tenders",
    "date": "26/06/2024,",
    "expiryDate": "",
    "name": "स्पोर्ट्स किट से संबंधित निविदा की EMD एवं TENDER FEE की राशि  से संबंधित Corrigendum (शुद्धिपत्र)",
    "corrigendum": "",
    "url": "img/tenders/Corrigendum_related_to_Sports_Ki.PDF"
  },

  {
    "key": "T4",
    "eventName": "tenders",
    "date": "25/06/2024,",
    "expiryDate": "",
    "name": "बिहार राज्य खेल प्राधिकरण के लिए खेल किट निविदाओं के लिए नई तिथि",
    "corrigendum": "",
    "url": "img/tenders/NewDateforPrebidl.PDF"
  },
  {
    "key": "T3",
    "eventName": "tenders",
    "date": "24/06/2024,",
    "expiryDate": "08/07/2024",
    "name": "बिहार राज्य खेल प्राधिकरण के लिए 03 वर्ष की अवधि के लिए खेल किट की आपूर्ति हेतु एजेंसी का चयन",
    "corrigendum": "",
    "url": "img/tenders/SPORTSKITFULLPDFFILE.pdf"
  },
  {
    "key": "T2",
    "eventName": "tenders",
    "date": "13/06/2024",
    "expiryDate": "05/07/2024",
    "name": "पाटलिपुत्र खेल परिसर छात्रावास, पटना के लिए पांच वर्षों की अवधि के लिए कैटरिंग एजेंसी के चयन हेतु निविदा",
    "corrigendum": "",
    "url": "img/tenders/MESSRPFFULLDETAILSPDFFILE.pdf"
  },
  {
    "key": "T1",
    "eventName": "tenders",
    "date": "12/06/2024",
    "expiryDate": "21/06/2024",
    "name": "EMPANELMENT OF TRANSPORATION AGENCY FOR BIHAR STATE SPORTS AUTHORITY FOR A PERIOD OF 03 YEARS",
    "corrigendum": "",
    "url": "img/tenders/TRAVEL_AGENCY_RFP_FULL_DETAILS_WORD_FILE.pdf"
  }
  // {
  //     "key": "T10",
  //     "eventName": "tenders",
  //     "date": "_",
  //     "expiryDate": "_",
  //     "name": "Corrigendum related to Travel Agency RFP",
  //     "corrigendum": "",
  //     "url": "img/tenders/Corrigendum_Travel Agency.docx"
  // },
  // {
  //     "key": "T9",
  //     "eventName": "tenders",
  //     "date": "11/01/2024",
  //     "expiryDate": "22/01/2024",
  //     "name": "EMPANELMENT OF TRANSPORATION AGENCY FOR BIHAR STATE SPORTS AUTHORITY FOR A PERIOD OF 03 YEAR",
  //     "corrigendum": "",
  //     "url": "img/tenders/RFP_FOR_Travel_Agency_for_BSSA_PDF_full_details.pdf"
  // },

  // {
  //     "key": "T8",
  //     "eventName": "tenders",
  //     "date": "15/12/2023",
  //     "expiryDate": "_____",
  //     "name": "ESTABLISHMENT OF PROJECT MONITORING UNIT FOR PROMOTING E-SPORTS OF BIHAR FOR A PERIOD OF 03 YEARS",
  //     "corrigendum": "",
  //     "url": "img/tenders/Corrigendum_E-Sports.pdf"
  // },
  // {
  //     "key": "T7",
  //     "eventName": "tenders",
  //     "date": "11/12/2023",
  //     "expiryDate": "18/12/2023",
  //     "name": "Regarding purchasing sports equipment for Center of Excellence",
  //     "corrigendum": "",
  //     "url": "img/tenders/weightliftingcenterofexcellence.pdf"
  // },
  // {
  //     "key": "T6",
  //     "eventName": "tenders",
  //     "date": "06/12/2023",
  //     "expiryDate": "15/12/2023",
  //     "name": "ESTABLISHMENT OF PROJECT MONITORING UNIT FOR PROMOTING E-SPORTS IN THE STATE OF BIHAR FOR A PERIOD OF 03 YEARS",
  //     "corrigendum": "",
  //     "url": "img/tenders/ESTABLISHMENTOFPROJECTMONITORING.pdf"
  // },
  // {
  //     "key": "T1",
  //     "eventName": "tenders",
  //     "date": "21/08/2023",
  //     "expiryDate": "11/09/2023",
  //     "name": "RFP for Selection of catering agency for Patliputra sports complex hostel, Patna for 05 years",
  //     "corrigendum": "",
  //     "url": "img/tenders/RFP for Selection of catering agency for Patliputra sports complex hostel, Patna for 05 years.pdf"
  // },
  // {
  //     "key": "T2",
  //     "eventName": "tenders",
  //     "date": "24/07/2023",
  //     "expiryDate": "24/07/2026",
  //     "name": "Letter No. 1003 Dated 24.07.2023 (4).pdf",
  //     "corrigendum": "",
  //     "url": "img/tenders/letter_number_1003_dated_24072023.pdf"
  // },
  // {
  //     "key": "T3",
  //     "eventName": "tenders",
  //     "date": "24/07/2023",
  //     "expiryDate": "24/07/2026",
  //     "name": "RFP COMPREHENSIVE HOUSEKEEPING, MAINTENANCE AND OPERATION CONTRACT (FOR 03 YEARS) FOR STADIUMS, OFFICES, HOSTELS AND OTHER ALLIED FACILITIES AT BIHAR STATE SPORTS AUTHORITY",
  //     "corrigendum": "",
  //     "url": "img/tenders/133343212303759748.pdf"
  // },
  // {
  //     "key": "T4",
  //     "eventName": "tenders",
  //     "date": "23/06/2023",
  //     "expiryDate": "23/06/2025",
  //     "name": "Corrigendum :(Pre Bid Queries) RFP for design, development, testing, commissioning, integration and maintenance of website at Bihar State Sports Authority (BSSA) for a period of 2 years",
  //     "corrigendum": "",
  //     "url": "img/tenders/TENDER.pdf"
  // },
  // {
  //     "key": "T5",
  //     "eventName": "tenders",
  //     "date": "23/06/2023",
  //     "expiryDate": "23/06/2025",
  //     "name": "Corrigendum :(Pre- bid- quarries) RFP for design, development, testing, commissioning, integration and maintenance of website at Bihar State Sports Authority (BSSA) for a period of 2 years  CamScanner 06-23-2023 13.31.09.pdf",
  //     "corrigendum": "",
  //     "url": "img/tenders/CamScanner 06-23-2023 13.31.09.pdf"
  // }
]